
import { defineComponent, onMounted, ref } from "vue";

const CFormCheckbox = defineComponent({
  name: "CFormCheckbox",
  emits: ["update:modelValue"],
  props: {
    elementID: { type: String, required: true },
    width: { type: String, default: "" },
    isDisabled: { type: Boolean, default: false },
    modelValue: { required: true },
  },
  setup(props, { emit }) {
    const checkbox = ref<HTMLInputElement | null>(null);

    function handleEmit(event: CheckboxEvent) {
      emit("update:modelValue", Boolean(event?.target?.checked));
    }

    onMounted(() => {
      if (checkbox.value) checkbox.value.checked = Boolean(props.modelValue);
    });

    return { checkbox, handleEmit };
  },
});

export interface CheckboxEvent extends Event {
  target: { checked: boolean } & EventTarget;
}

export default CFormCheckbox;
