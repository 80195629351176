
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useRouterUtils } from "@/utils/mixins";

import CSpinner from "@/components/spinner.vue";
import CFormCheckbox from "@/components/form/checkbox.vue";
import CFormInputText from "@/components/form/input-text.vue";
import CPatientSearch from "@/views/patient/search.vue";

import { Patient } from "@/store/patient/types";

import { addMoneyMask } from "@/utils/formatters";

const VLaboratory = defineComponent({
  name: "VLaboratory",
  components: { CSpinner, CFormInputText, CFormCheckbox, CPatientSearch },
  setup() {
    useHead({ title: "Agendar exames laboratoriais | obmed" });
    const store = useStore();
    const route = useRoute();
    const { normalizeRouteParam } = useRouterUtils();

    const showOnlySelectedExams = ref(false);
    const selectedExams = ref<{ [key: string]: boolean }>({});
    const selectedExamsIDs = computed(() => Object.keys(selectedExams.value).filter((key) => selectedExams.value[key]));
    const patient = ref<Patient | null>(null);

    const steps = ref(["Paciente", "Exame", "Estabelecimento"]);
    const currentStep = computed(() =>
      route.name?.toString() == "laboratory" && patient.value?.id
        ? steps.value?.findIndex((step) => step === "Exame")
        : route.name?.toString().includes("-establishment")
        ? steps.value?.findIndex((step) => step === "Estabelecimento")
        : !patient.value?.id
        ? steps.value?.findIndex((step) => step === "Paciente")
        : (steps.value?.length || 0) - 1
    );

    const list = computed(() => store.getters.laboratoryList);
    const filteredList = computed(() =>
      list.value.filter(
        (item) =>
          !showOnlySelectedExams.value ||
          (showOnlySelectedExams.value && selectedExamsIDs.value.includes(String(item.id)))
      )
    );

    const search = ref("");
    const page = ref(1);
    const loading = reactive({ list: false });

    async function searchLaboratory() {
      loading.list = true;
      await store.dispatch("searchLaboratory", { page: page.value, search: search.value });
      loading.list = false;
    }

    function handleScroll() {
      const windowTotalHeight = Number(document.scrollingElement?.scrollHeight);
      const windowHeight = Number(document.scrollingElement?.clientHeight);
      const scrollPosition = Number(document.scrollingElement?.scrollTop);
      const aQuarterOfTheScreen = windowHeight / 4;

      if (windowHeight + scrollPosition >= windowTotalHeight - aQuarterOfTheScreen && !loading.list)
        page.value = page.value + 1;
    }

    onMounted(() => {
      if (route.name?.toString() === "laboratory") window.addEventListener("scroll", handleScroll);
    });

    searchLaboratory();
    watch([page, search], searchLaboratory);

    watch(
      () => route.name,
      (name) => {
        if (String(name) !== "laboratory") window.removeEventListener("scroll", handleScroll);
      }
    );

    return {
      list,
      patient,
      filteredList,
      selectedExams,
      selectedExamsIDs,
      showOnlySelectedExams,
      search,
      loading,
      steps,
      currentStep,
      addMoneyMask,
      normalizeRouteParam,
    };
  },
});

export default VLaboratory;
