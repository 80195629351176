
import { defineComponent } from "vue";

export type CTabItem = { label: string; icon: string; component: string };

const CSpinner = defineComponent({
  name: "CSpinner",
});

export default CSpinner;
