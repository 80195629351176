import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { PaginationEvent } from "@/store/types";
import { formatDateTimeEn } from "./formatters";

export function usePagination(params: {
  module: string | any;
  setPage: string | any;
  field?: string;
  updateList: () => any;
}) {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // @ts-expect-error temp
  // eslint-disable-line no-use-before-define
  const pagination = computed(() => store.state[params.module][params.field || "pagination"]);

  function handleUpdateCurrentPage(event: PaginationEvent) {
    const page = event.page + 1;

    router.replace({ query: { ...route.query, pg: page } });
    store.commit(params.setPage, { page });

    params.updateList();
  }

  store.commit(params.setPage, { page: 1 });
  router.replace({ query: { ...route.query, pg: 1 } });

  return { pagination, handleUpdateCurrentPage };
}

export function useFilters(excludeFilter: string[] = []) {
  const route = useRoute();

  const hasFilters = computed(() =>
    Boolean(Object.keys(route.query).filter((item) => !["pg", ...excludeFilter].includes(item)).length)
  );
  const hasAnyFilters = computed(() =>
    Boolean(Object.keys(route.query).filter((item) => !["pg"].includes(item)).length)
  );

  return { hasFilters, hasAnyFilters };
}

export function useDownloadFile() {
  function downloadPDF({ content }: { content: any }) {
    const linkEl = document.createElement("a");
    linkEl.href = URL.createObjectURL(new Blob([content], { type: "application/pdf" }));
    linkEl.setAttribute("download", `obmed_${formatDateTimeEn(new Date(), "current").replace(" ", "_")}.pdf`);
    linkEl.click();
  }

  function downloadCSV({ content }: { content: any }) {
    const linkEl = document.createElement("a");
    linkEl.href = URL.createObjectURL(new Blob([content], { type: "application/csv" }));
    linkEl.setAttribute("download", `obmed_${formatDateTimeEn(new Date(), "current").replace(" ", "_")}.csv`);
    linkEl.click();
  }

  return { downloadPDF, downloadCSV };
}

export function useOpenFile() {
  function openPDF({ url }: { url: string }) {
    const linkEl = document.createElement("a");
    linkEl.setAttribute("href", url);
    linkEl.setAttribute("rel", "noreferrer");
    linkEl.setAttribute("target", "_blank");
    linkEl.click();
  }

  return { openPDF };
}

export function useRouterUtils() {
  function convertQueryToArray(query: any) {
    try {
      return String(query).split(",");
    } catch {
      return [];
    }
  }

  function convertQueryToNumberArray(query: any) {
    try {
      return convertQueryToArray(query).map((item) => Number(item));
    } catch {
      return [];
    }
  }

  function normalizeRouteParam(routeParam?: string | null) {
    try {
      return routeParam?.replaceAll(" ", "_").toLocaleLowerCase() || "";
    } catch {
      return "";
    }
  }

  return { convertQueryToArray, convertQueryToNumberArray, normalizeRouteParam };
}
