import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "value", "disabled"]
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "c-form-checkbox-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['c-form-checkbox', { isDisabled: _ctx.isDisabled }]),
    style: _normalizeStyle({ width: _ctx.width })
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      id: _ctx.elementID,
      value: _ctx.modelValue,
      ref: "checkbox",
      disabled: _ctx.isDisabled,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleEmit && _ctx.handleEmit(...args)))
    }, null, 40, _hoisted_1),
    _createElementVNode("label", { for: _ctx.elementID }, [
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 8, _hoisted_2),
    _renderSlot(_ctx.$slots, "before")
  ], 6))
}